/* global style */
body {
  background-color: #FFFEFA;
}
.page-wrapper {
  overflow: hidden;
  opacity: 0;
}
img {
  width: 100%;
}

a {
  text-decoration: none;
  color: #FFFFFF;
}
ol,ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.global-padding {
  max-width: 1250px;
  margin: 0 auto;
}
.ppadding {
  padding-left: 3vw;
  padding-right: 3vw;

}

.is-flex {
  display: flex;
}

.is-relative {
  position: relative;
}
.is-absolute {
  position:absolute;
}

.container {
  padding: 0px;
  max-width: 100%;
}
.container .row {
  margin-left: 0;
  margin-right: 0;
}
.container .row > *{
  padding: 0;
}
/* lenis */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* end lenis */

/*
|------------------------------------------------------
|  Typograph
|------------------------------------------------------
*/
@font-face {
  font-family: BasisGrotesquePro-Bold;
  src: url(fonts/BasisGrotesquePro-Bold.ttf);
}
@font-face {
  font-family: BasisGrotesquePro-Light;
  src: url(fonts/BasisGrotesquePro-Light.ttf);
}
@font-face {
  font-family: BasisGrotesquePro-Regular;
  src: url(fonts/BasisGrotesquePro-Regular.ttf);
}
@font-face {
  font-family: BasisGrotesquePro-italic;
  src: url(fonts/Basis-Grotesque-Pro-Italic.ttf);
}

h1,
.mission-sub{
  font-family: BasisGrotesquePro-Bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
h1 {
  opacity:1;
  transform: translate(0px, 0px);
}
h2 {
  font-family: BasisGrotesquePro-Bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 0;

}
h3 {
  font-family: BasisGrotesquePro-Bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 0;

}
h4 {
  font-family: BasisGrotesquePro-Bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 0;

}

p {
  font-family: BasisGrotesquePro-Regular;
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}




/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  h1,
.mission-sub{
  font-size: clamp(26px,2.5vw,40px);
  line-height: clamp(30px,2.8vw,46px);
  }
  h3 {
    font-size: clamp(20px,1.5vw,26px);
    line-height: clamp(25px,2vw,30px);
  }
}
@media (max-width: 767px) {
  .ppadding {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  h1, .mission-sub {
    font-size: 26px;
    line-height: 30px;
  }
  h3 {
    font-size: 22px;
    line-height: 27px;

  }
}
