.projectList-img {
  filter: brightness(1);
  transition: filter 0.35s;
}
.projectList-img:hover {
  filter: brightness(1.1) blur(0.5px);
}
/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
}