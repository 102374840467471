.footer {
  background-color: #000000;
  padding: 70px 3vw 80px;
}
.footer p {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 18px;
  width: 61%;
}
.footer .row:first-child {
  justify-content: space-between;
}
.footer .row:nth-child(2) {
  margin: 75px 0 136px;
}
.footer .logo{
  width: 200.18px;
  height: auto;
}
.footer .row:nth-child(2) .row .left {
  display: flex;
  flex-direction: column;
}
.footer .socials {
  width: fit-content;
  margin-left: auto;
}
.copyright-row {
  align-items: flex-end;
}
.copyright-row a,
.copyright-row .copyright {
  font-family: BasisGrotesquePro-Regular;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #FFFFFF;
}
.footer .row:last-child .right {
  text-align: right;
}
.is-bold {
  font-family: BasisGrotesquePro-Bold;

}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .footer {
    padding: clamp(30px,3vw,70px) 3vw clamp(60px,2.5vw,80px);
  }
  .footer .row:nth-child(2) {
    margin-bottom: clamp(60px,7vw,136px);
  }
}
@media (max-width: 991px) {
  .footer p {
    width: 100%;
  }
  .footer .row:first-child .left {
    margin-bottom: 60px;
  }
  .footer .row:nth-child(2) > .left {
    margin-bottom: 40px;
  }
  .footer .row:nth-child(2) .row .left {
    margin-bottom: 0px;
  }
  .footer .row:last-child .right {
    text-align: left;
  }
  .footer .socials {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 30px 4vw 60px;
  }
  .footer .logo {
    width: 230px;
  }
  .footer .row:nth-child(2) {
    margin: 152px 0 60px;
  }
  .footer .row:nth-child(2) > .left {
    margin-bottom: 138px;
  }
}