.companyAccordion .accordion-button{
  font-family: BasisGrotesquePro-Bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #FFFFFF;
  padding: 30px 0;
}
.companyAccordion .accordion-button::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/%3e%3c/svg%3e");
}

.companyAccordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/%3e%3c/svg%3e");
}

.companyAccordion .accordion-item
 {
  border-top: 1px solid #FFFFFF!important;
}
.companyAccordion .accordion-item:last-child {
  border-bottom: 1px solid #FFFFFF;

}
.companyAccordion .accordion-collapse {
  border-top: 1px solid #FFFFFF;
}


.companyAccordion ul {
  margin-bottom: 30px;
}
.companyAccordion li {
  font-family: BasisGrotesquePro-Bold;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #FFFFFF;
  margin-bottom: 0px;
}
.is-italic {
  font-family: BasisGrotesquePro-italic;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .companyAccordion .accordion-button {
    font-size: clamp(32px,2vw,40px);
    line-height: clamp(38px,2.5vw,46px);
    padding: clamp(12px, 1.5vw, 30px) 0;
  }
  .companyAccordion li{
    font-size: clamp(22px,1.3vw,26px);
    line-height: clamp(27px,2vw,40px);
  }
}
@media (max-width: 767px) {
  .companyAccordion .accordion-button {
    font-size: 32px;
    line-height: 38px;
    padding: 12px 0;
  }
  .companyAccordion li {
    font-size: 22px;
    line-height: 27px;
  }
  .accordion-body {
    padding: 30px 0 60px;
  }

}