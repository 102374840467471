.emailSubscribe-container {
  /* width: fit-content;
  margin-left: auto; */

}
.emailSubscribe {
  border-bottom: 1px solid #FFFFFF;
  display: flex;
  justify-content: space-between;
}
.emailSubscribe input {
  background: transparent;
  border: none;
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -1px;
  color:#FFFFFF;
}
.emailSubscribe input::placeholder  {
  color:#FFFFFF;

}
.emailSubscribe .submit-btn {
  background: transparent;
  border: none;
}
.emailSubscribe .submit-btn img{
  width: 14.02px;
  height: 10.92px;
}

.footer .emailSubscribe-container p{
  width: 100%;
  margin-top: 12.5px;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

/* default custmize */
form input:focus {
  outline: none;
  background: transparent;
}
form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form input:-webkit-autofill:active {
  transition: background-color 1000s;
  -webkit-text-fill-color: #FFFFFF !important;
}

#subscription-message {
  font-family: BasisGrotesquePro-Bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #FFFFFF;
  margin-top: 10px;
}

