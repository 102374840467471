.projectsPage .projects-section {
  padding-top: 250px;
  padding-bottom: 300px;
}
.projectsPage h1 {
  margin-bottom: 200px;
}
.projectsPage .grid-container {
  display: grid;
  gap: 100px 54px;
  grid-template-columns: auto auto;

}
.projectsPage .project-wrapper  {
  margin-right: 0px;
}
.projectsPage .project-wrapper img{
  width: 100%;
}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .projectsPage .projects-section {
    padding-top: clamp(150px,14vw,250px);
    padding-bottom: clamp(120px,17vw,300px);
  }
  .projectsPage h1 {
    margin-bottom: clamp(120px,10.5vw,200px);
  }
  .projectsPage .grid-container {
    gap: clamp(60px,5.5vw,100px) clamp(25px,3vw,54px);
  }
}
@media (max-width: 767px) {
  .projectsPage .projects-section {
    padding-top: 150px;
    padding-bottom: 120px;
  }
  .projectsPage h1 {
    margin-bottom: 120px;
  }
  .projectsPage .grid-container {
    grid-template-columns:auto;
    padding: 0 4vw;
    gap: 60px 0;
  }
}