.contactPage {
  /* padding-top: 258px; */
  background: #000000;
  height: 100vh;
  /* padding-bottom: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}
.contactPage h1,
.contactPage P {
  color: #FFFFFF;
}

.contactPage .row {
  justify-content: space-between;
}
.contactPage .first-row {
  margin-bottom: 150px;
}
.contactPage .row:first-child .left p {
  margin: 20px 0 40px;
}
.contactPage .row:first-child .left a {
  display: block;
  margin-bottom: 20px;
  width: fit-content;
}
.contactPage .row:last-child .right {
  text-align: right;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1900px) {
  .contactPage {
    /* padding-top: clamp(120px,11vw,258px);
    padding-bottom: clamp(120px,5vw,150px); */
  }
  .contactPage .first-row {
    margin-bottom: clamp(80px,5vw,150px);

  }
  .contactPage .row:first-child .left p {
    margin: 20px 0 clamp(30px,2vw,40px);
  }
  .contactPage .row:first-child .left a {
    margin-bottom: clamp(8px,1vw,20px);
  }
}
@media (max-width: 767px) {
  .contactPage {
    height: auto;
    padding: 150px 4vw 120px;

  }
  .contactPage h1 {
    font-size: 42px;
    line-height: 50px;
  }
  .contactPage .row:first-child .left p {
    margin: 30px 0;
  }
  .contactPage .row:first-child .left a {
    margin-bottom: 70px;
    font-family: BasisGrotesquePro-Bold;
  }
  .contactPage .row:last-child .right {
    text-align: left;
  }
  .contactPage .first-row {
    margin-bottom: 0px;
  }
  .contactPage {
    border-bottom: 1px solid #FFFFFF;
  }
}