.projectsDetailPage {
  opacity: 1;
}
.projectsDetail-section {
  padding-top:200px ;
  padding-bottom: 285px;
}
.projectsDetail-section h1{
  /* font-size: 40px;
  line-height: 46px; */
}
.projectsDetail-section h4 {
  margin: 10px 0 50px;
}
.projectsDetail-section .row.intro {
  margin-bottom: 200px;
}
.projectsDetail-section .row.intro .wrapper {
  width: 74.5%;
} 
.projectsDetail-section .row.flickity .img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.projectsDetail-section .row.flickity .img{
  height: 65.74vh;
  width: auto;
}
 /* flickity button */
 
 .projectsDetail-section .carousel {
  text-align: center;
 }
 .projectsDetail-section .flickity-viewport {
  overflow-x: hidden;
 }
 .projectsDetail-section .flickity-button {
  position: absolute;
  top: auto;
  bottom: -3px;
  right: 0;
  left: auto;
  padding: 0;
}
.projectsDetail-section .flickity-button.previous {
  left: 0;
  right: auto;
}
.projectsDetail-section .flickity-page-dots {
  margin-top: 26.6px;
}
.projectsDetail-section .row.video,
.projectsDetail-section .bg-video:nth-child(2)  {
  margin-top: 200px;
}
.projectsDetail-section .row.video .bg-video-container,
.projectsDetail-section .row.video .bg-video[poster] {
  width: 100%;
  height: auto;
  min-height: auto;
} 

.carousel {
  cursor: pointer;
}
.projectsCarouselSection {
  background: #000000;
  padding-top: 200px;
  padding-bottom: 266px;
  border-bottom: 1px solid #FFFFFF;
}
.projectsDetailPage .projects-carousel {
  margin-top: 0px;
}
.projectsCarouselSection h2,
.projectsCarouselSection h4,
.projectsCarouselSection p  {
  color: #FFFFFF;
}

.projectsCarouselSection .flickity-page-dots .dot {
  background-color: #FFFFFF;
}
.projectsCarouselSection .flickity-button-icon {
  fill: #FFFFFF;
}
.projectsDetail-section .flickity-button.next .flickity-button-icon {
  margin-bottom: 6px;
}



/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .projectsDetail-section {
    padding-top: clamp(120px,9.5vw,200px);
    padding-bottom: clamp(120px,16vw,285px);
  }
  .projectsDetail-section .row.intro {
    margin-bottom: clamp(120px,9.5vw,200px);
  }
  .projectsDetail-section .row.video,
  .projectsDetail-section .bg-video:nth-child(2)  {
    margin-top: clamp(120px,9.5vw,200px);
  }
  .projectsCarouselSection {
    padding-top: clamp(120px,9.5vw,200px);
    padding-bottom: clamp(120px,15vw,266px);
  }
  /* .projectsDetail-section h1 {
    font-size: clamp(30px,2.5vw,40px);
        line-height: clamp(35px,2.8vw,46px);
  }
  .projectsDetail-section h4 {
    font-size: clamp(20px,1.5vw,24px);
    line-height: clamp(25px,2vw,30px);
    margin-bottom: clamp(30px,3vw,50px);
  } */
}
@media (max-width: 991px) {
  .projectsDetail-section .row.intro .wrapper {
    width: 100%;
  }
  .projectsDetail-section .row.intro .left{
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .projectsDetail-section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .projectsDetail-section h1 {
    font-size: 42px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .projectsDetail-section {
    padding: 120px 8vw!important;
  }
  .projectsDetail-section h4 {
    margin: 20px 0 60px;
    font-size: 22px;
    line-height: 27px;
  }
  .projectsDetail-section .row:first-child p {
    padding-right: 2.6vw;
  }
  .projectsDetail-section .row.intro{
    margin-bottom: 60px;
  }
  .projectsDetail-section .row.video,
  .projectsDetail-section .bg-video:nth-child(2)  {
    margin-top: 120px;
  }
  .projectsCarouselSection {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .projectsDetailPage .projects-carousel {
    padding-left: 4vw;
  }


}