.intro-wrapper {
  /* padding-top: 348px;
  padding-bottom: 366px ; */
}
.intro-wrapper img {
  width: 14.02px;
  height: 10.92px;
  margin-left: 13px;
}
.intro-wrapper a {
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000000;
  margin-top: 50px;
  display: inline-block;

}


@media (max-width: 991px) {
  .intro-wrapper a {
    margin-top: clamp(25px,4.5vw,50px);
  }
}
@media (max-width: 767px) {
  .intro-wrapper a {
    margin-top: 60px;
  }
}