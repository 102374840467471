.bnr {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bnr div{
  font-family: BasisGrotesquePro-Bold;
  font-size: 54px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #FFFFFF;

}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .bnr div {
    font-size: clamp(46px,3vw,54px);
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .bnr div {
  font-size: 46px;
  line-height: 54px;
  width: 77%;
  text-align: center;
  }
}