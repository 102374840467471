.accordion-button {
  font-family: BasisGrotesquePro-Bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  padding: 20px 0;
  background-color: transparent;
  border-radius: 0px;
}
.accordion li{
  margin-bottom: 8px;
}
.accordion-item {
  background-color: transparent;
  border: none;
  border-radius: 0px;
  border-top: 1px solid #000000!important;
}
.accordion-item:first-of-type {
  border-radius: 0;
}
.accordion-item:last-child {
  border-bottom: 1px solid #000000;
  border-radius: 0px;
}
.accordion-collapse {
  border-top: 1px solid #000000;
}
.accordion-button:not(.collapsed) {
  background: transparent;
  color: #000000;
  box-shadow: none;
}
.accordion-button::after {
  /* display: none; */
}

.accordion-button::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23'%3e%3cpath fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/%3e%3c/svg%3e");
}


.accordion-body {
  padding: 60px 0;
}
.accordion-body h3{
  margin-bottom: 30px;
}

.accordion .has-margin{
  margin-top: 30px;
}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .accordion-button {
    font-size: clamp(22px,1.5vw,26px);
    line-height: clamp(27px,2vw,30px);
    padding: clamp(12px,1vw,20px) 0;
  }
  .accordion-body{
    padding: clamp(30px,3vw,60px) 0;
  }
}
@media (max-width: 767px) {
  .accordion-button {
    font-size: 22px;
    line-height: 27px;
    padding: 12px 0;
  }
  .accordion-body {
    padding: 30px 0;
  }
}