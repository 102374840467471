.header {
  /* padding-top: 22.7px;
  padding-bottom: 22.7px; */
  padding: 2px 2vw;
  /* margin:0 2vw ; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  transform: translateY(0%);
  transition: all 0.5s;
}
.header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .logo {
  width: 171.91px;
  height: auto;
}
.header .logo .st0{
  fill: #FFFFFF;
}
.header .logo.black .st0{
  fill: #000000;
}
.header .menu-text {
  font-family: BasisGrotesquePro-Bold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: capitalize;
  background-color: transparent;
  border: 0px;

  
}

/* offcanvas */
.offcanvas {
  font-family: BasisGrotesquePro-Bold;
  background: #000000;
  color: #FFFFFF;
}
.offcanvas.constec-offcanvas {
  width: 50vw;
  height: 100vh;
}
.header .offcanvas-backdrop.show {
  opacity: 0;
}
.offcanvas .menu-text {
  margin-left: auto;
}
.offcanvas-header {
  padding: 29px 2vw;
}

.offcanvas-body {
  padding: 69px 2vw 78px;

}
.offcanvas-body .container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.nav {
  width: fit-content;
}
.nav p{
  font-family: BasisGrotesquePro-Bold;
  color: #FFFFFF;
  font-size: 50px;
  line-height: 57px;
  letter-spacing: -1px;
  text-transform: capitalize;
}
.socials {
  display: flex;
  flex-direction: column;
}
.social-wrapper
 {
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px!important;
  line-height: 30px;
  letter-spacing: -1px;
}
.copyright-wrapper {
  font-family: BasisGrotesquePro-Regular;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -1px;

}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .offcanvas-body {
    padding: clamp(56px,1.5vw,69px) 2vw 78px;
  }
  .header .logo{
    width: clamp(141.91px,8.5vw,171.91px);
  }
  .offcanvas-header {
    padding: clamp(18px,0vw,29px) 2vw;
  }
}
@media (max-width: 991px) {
  .offcanvas.constec-offcanvas {
    width: 90vw;
  }
}
@media (max-width: 767px) {
  .header .logo {
    width: 145px;
  }
  .contact-text {
    margin-left: 7px;
  }
  .offcanvas.constec-offcanvas {
    width: 100vw;
  }
  .offcanvas-header {
    padding: 20px 4vw;
  }
  .header .menu-text {
    font-size: 22px;
    line-height: 27px;
  }
  .menu-text.nav {
    margin-top: 11px;
    margin-bottom: 11px;

  }
  .offcanvas-body {
    padding: 56px 4vw 89px;
  }
  .social-wrapper .left {
    margin-bottom: 50px;
  }
  .copyright-wrapper .copyright {
    margin-top: 8px;
  }
  
}