.companyPage .companyIntro {
  padding-top: 250px;
  padding-bottom: 250px;
  background-color: #FFFEFA;
}
.companyPage h1 span:first-child {
  margin-bottom: 60px;
}
.companyPage .companyDetail {
  padding-top: 200px;
  padding-bottom: 250px;
  background-color: #000000;
}
.companyDetail h4 {
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: none;
}
.companyDetail .mission-sub {
  color: #ffffff;
  width: 95%;
}
.companyDetail p {
  color: #ffffff;
}
.companyDetail .row:first-child {
  margin-bottom: 250px;
}
.companyDetail .row:nth-child(2) .left p{
  width: 75%;
}

.companyPage .whyUs {
  padding-top: 200px;
  padding-bottom: 250px;
}
.companyPage .whyUs .mission-sub {
  margin: 20px 0 150px;
}
.companyPage .whyUs li {
  font-family: BasisGrotesquePro-Bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #000000;
  padding: 30px 0;
  border-top: 1px solid #000000;
  width: 62%;
}
.companyPage .whyUs li:last-child {
  border-bottom: 1px solid #000000;

}

.companyPage .connect {
  padding-top: 200px;
  padding-bottom: 250px;
  border-top: 1px solid #000000;
}
.companyPage .connect h4 {
  margin-bottom: 90px;
}
.companyPage .connect .intro-wrapper{
  margin-left: 3.3vw;
}
.companyPage .connect h3 {
  font-size: 44px;
  line-height: 52px;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .companyPage .companyIntro{
    padding-top: clamp(150px,15vw,250px);
    padding-bottom: clamp(150px,15vw,250px);
  }
  .companyPage h1 span:first-child {
    margin-bottom: clamp(40px,3.2vw,60px);
  }
  .companyPage .companyDetail,
  .companyPage .whyUs,
  .companyPage .connect  {
    padding-top: clamp(120px,9.5vw,200px);
    padding-bottom: clamp(120px,15vw,250px);
  }
  .companyDetail .row:first-child{
    margin-bottom: clamp(120px,15vw,250px);
  }
  .companyPage .whyUs .mission-sub {
    margin-bottom: clamp(60px,7vw,150px);
  }
  .companyPage .whyUs li{
    font-size: clamp(22px,2vw,40px);
    line-height: clamp(27px,2.5vw,46px);
    padding: clamp(20px, 1.5vw, 30px) 0;

  }
  .companyPage .connect h4 {
    margin-bottom: clamp(60px,5vw,87px);
  }
  .companyPage .connect h3 {
    font-size: clamp(32px,3vw,44px);
    line-height: clamp(38px,3.5vw,52px);
  }
}
@media (max-width: 767px) {
  .companyPage .companyIntro {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .companyPage h1 span:first-child {
    margin-bottom: 40px;
  }
  .companyDetail .row:first-child {
    margin-bottom: 120px;
  }
  .companyDetail .row:first-child .left {
    padding: 0 4vw;
    margin-bottom: 60px;
  }
  .companyDetail h4 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  .companyDetail .mission-sub {
    font-size: 32px;
    line-height: 38px;
  }
  .companyDetail .row:nth-child(2) {
    padding: 0 4vw;
  }
  .companyDetail .row:nth-child(2) .left p {
    width: 96%;
  }
  .companyDetail .row:nth-child(2) .left  {
    margin-bottom: 60px;
  }
  .companyPage .companyDetail,
  .companyPage .whyUs,
  .companyPage .connect  {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .companyPage .whyUs {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .companyPage .connect {
    padding-left: 8vw;
    padding-right: 8vw;

  }
  .companyPage .whyUs li {
    width: 100%;
    font-size: 22px;
    line-height: 27px;
    padding: 20px 0;
  }
  .companyPage .whyUs .mission-sub {
    font-size: 32px;
    line-height: 38px;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .companyPage .connect h4 {
    margin-bottom: 60px;
  }
  .companyPage .connect .intro-wrapper {
    margin-left: 8vw;
  }
}