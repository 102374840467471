.home {
  position: relative;
  z-index: 2;
}
.bg-video-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 1080px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bg-video {
  object-fit: cover;
}
.bg-video[poster] {
  height: 100vh;
  min-height: 1080px;
  width: 100vw;
}

.home-intro,
.home .expertiseServices {
  padding-top: 200px;
  padding-bottom: 250px;
  border-bottom: 1px solid #000000;
}
.home .contact-section {
  padding-top: 200px;
  padding-bottom: 250px;
}

.projects-carousel {
  /* padding-left: 19vw; */
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 252.8px;
  overflow: visible;
}
.projects-carousel h2{
  margin-bottom: 77px;
}

.home .projectsIntro {
  margin-top: 200px;
}
.home .projectsIntro .left,
.home .expertiseServices .row:last-child .right{
  padding-top: 30px;
}
.home .projectsIntro h3 {
  width: 87%;
}

.home .expertiseServices h2 {
  margin-bottom: 30px;
}
.home .expertiseServices .left h3 {
  font-family: BasisGrotesquePro-Regular;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -1px;
  width: 75%;
}
.home .expertiseServices .row:last-child {
  margin-top: 200px;
}

.home .expertiseServices .row:last-child .left img{
  width: 90%;
}
.home .expertiseServices .row:last-child .intro-wrapper{
  width: 82%;
}

.home .contact-section h2{
  margin-bottom: 87px;
}
.home .contact-section h3{
  font-size: 44px;
  line-height: 52px;

}
.home .contact-section .intro-wrapper {
  margin-left: 3.5vw;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .home .projectsIntro,
  .home .expertiseServices .row:last-child {
    margin-top: clamp(127px,11.5vw,200px);
  }
  .home-intro, .home .expertiseServices,
  .home .contact-section {
    padding-top: clamp(120px,11.5vw,200px);
    padding-bottom: clamp(120px,15.5vw,250px);
  }
  .home .projects-carousel {
    margin-top: clamp(120px,15.5vw,252.8px);
  }
  .projects-carousel h2 {
    margin-bottom: clamp(60px,4vw,77px);
  }
  .home .contact-section h2 {
    margin-bottom: clamp(60px,5vw,87px);
  }
  .home .contact-section h3{
    font-size: clamp(32px,3vw,44px);
    line-height: clamp(38px,3.5vw,52px);
  }
}
@media (max-width: 1100px) {
}
@media (max-width: 991px) {
  .home .projectsIntro .left, .home .expertiseServices .row:last-child .right {
    padding-top: 0;
  }
  .home .projectsIntro h3 {
    width: 90%;
    
  }

}
@media (max-width: 767px) {
  .mobileBnr-Logo {
    margin: 0;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 323.84px;
    height: auto;

  }
  .home-intro, .home .expertiseServices, .home .contact-section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .home .projects-carousel {
    margin-top: 120px;
    margin-left: 4vw;
  }
  .projects-carousel h2 {
    margin-bottom: 60px;
  }
  .home .projectsIntro {
    margin-top: 127px;
  }
  .home .projectsIntro h3 {
    width: 100%;
  }
  .home .projectsIntro .left {
    margin-bottom: 30px;
  }
  .home .expertiseServices .row:last-child .right {
    margin-top: 30px;
  }
  .home .expertiseServices h2,
  .home .expertiseServices .row:first-child  {
    padding: 0 4vw;
  }
  .home .expertiseServices .row:first-child .left{
    margin-bottom: 30px;
  }
  .home .expertiseServices .left h3 {
    width: 90%;
  }
  .home .expertiseServices .row:last-child .left img {
    width: 100%;
  }
  .home .expertiseServices .row:last-child {
    margin-top: 120px;
  }
  .home .expertiseServices .row:last-child .intro-wrapper {
    width: 100%;
  }
  .home .contact-section {
    padding-left: 8vw;
    padding-right: 8vw;
  }
  .home .contact-section h2 {
    margin-bottom: 60px;
  }
  .home .contact-section h3 {
    font-size: 32px;
    line-height: 38px;
  }
  .home .contact-section .intro-wrapper {
    margin-left: 8vw;
  }
}