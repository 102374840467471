#signup-form #first-name,
#signup-form #last-name {
  width: 100%;
}
#signup-form #email, 
#signup-form #enquiry {
  width: 100%;
}
#signup-form #email {
  margin: 18.5px 0;
}
#signup-form #enquiry {
  height: 130px;
}
#signup-form .nameWrapper {
  display: flex;
  justify-content: space-between;
}
.nameWrapper .wrapper {
  width: 100%;
}
.nameWrapper .wrapper .left {
  padding-right: 5%;
}
.nameWrapper .wrapper .right {
  padding-left: 5%;
}
#signup-form input,
#signup-form textarea
 {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color:#FFFFFF;
  padding-bottom: 14.5px;
  border-radius: 0px;
}
#signup-form input::placeholder,
#signup-form textarea::placeholder {
  color:#FFFFFF;

}
#signup-form .submit-btn {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color:#FFFFFF;
  margin: 110px 0 30.5px;
}
.signup-container p {
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.5px;
}
#signup-message {
  font-family: BasisGrotesquePro-Bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
  margin-top: 40px;
}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1900px) {
  #signup-form #enquiry {
    height: clamp(110px,6.5vw,130px);

  }
  #signup-form .submit-btn {
    margin: clamp(30px,6vw,110px) 0 clamp(20px,2vw,30.5px);
  }
}

@media (max-width: 767px) {
  .nameWrapper .wrapper .left,
  .contactPage .row:last-child .right {
    padding: 0;
  }
  #signup-form #first-name {
    margin-bottom: 30.5px;
  }
  #signup-form #email {
    margin: 30.5px 0;
  }
  #signup-form #enquiry {
    height: 110px;
  }
  #signup-form .submit-btn {
    margin: 30px 0 15px;
  }
  #signup-message {
    margin-top: 30px;
  }
}