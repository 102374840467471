.bnr-privacy {
  padding-top: 250px;
  padding-bottom: 258px;
  max-width: 1250px;
}
.bnr-privacy h1 {
  margin-bottom: 150px;
}
.bnr-privacy h2,
.bnr-privacy p {
  font-size: 18px;
  line-height: 20px;
}
.bnr-privacy h2 {
  font-family: BasisGrotesquePro-Bold;
  margin-bottom: 25px;
}
.bnr-privacy p{
  margin-bottom: 30px;
}
.bnr-privacy p:first-child,
.bnr-privacy p:last-child
{
  margin-bottom: 0px;}

  

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .bnr-privacy {
    padding-top: clamp(150px,15vw,250px);
    padding-bottom: clamp(120px,15vw,258px);
  }
  .bnr-privacy h1{
    margin-bottom: clamp(60px,7vw,150px);
  }
}
@media (max-width: 767px) {
  .bnr-privacy {
    padding-top: 150PX;
    padding-bottom: 120PX;
  }
  .bnr-privacy h1 {
    margin-bottom: 60px;
  }
  .bnr-privacy .heading-wrapper > div {
    padding: 0 4vw;
  }
  .bnr-privacy h2 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .bnr-privacy p {
    margin-bottom: 25px;
  }
}
