

.project-wrapper {
  overflow: hidden;
  margin-right: 3vw;
}
.project-wrapper img{
  /* width: 29vw; */
  width: 540px;
  height: auto;
}
.project-wrapper h4 {
  margin: 30px 0 14px;
}
  /* button */
  .carousel {
    position: relative;
    overflow: visible!important;
  }
  .flickity-viewport {
    /* overflow-x: visible!important; */
  }
  .flickity-button {
    position: absolute;
    top: -85px;
    /* right: 17vw; */
    right: 1vw;
    /* width: 47px;
    height: 47px; */
    background: transparent;
    border: none;

  }
  .flickity-button-icon {
    fill: #000000;
    height: 18px;
  }
  .flickity-button.previous {
    right: 4vw;
  }

  /* dots */
  .flickity-page-dots {
    display: inline-flex;
    margin-top: 30px;
  }
  .flickity-page-dots .dot{
    height: 8px;
    background: #000000;
    width: 8px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: 0.3;
    transition: opacity 0.35s ease;
  }
  .flickity-page-dots .dot.is-selected {
    opacity: 1;

  }

  /* arrow */
  .flickity-button.next .flickity-button-icon {
    margin-bottom: 20px;
  }

  @media (max-width: 1100px) {
    .project-wrapper img{
      width: 42vw;
    }
  }
  @media (max-width: 991px) {
    .project-wrapper img{
      width: 47vw;
    }
  }
  @media (max-width: 767px) {
    .project-wrapper img{
      width: 82vw;
    }
    .project-wrapper {
      margin-right: 6vw;
    }
    .flickity-button {
      right: 5vw;
    }
    .flickity-button.previous {
      right: 15vw;
    }
    .project-wrapper h4 {
      font-size: 22px;
      line-height: 27px;
      margin: 30px 0 10px!important;
    }
    .flickity-page-dots {
      margin-top: 45px;
    }
  }